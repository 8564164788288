<template>
  <a-modal v-model="isShow" :title=" isAdd ? '新增汇付个人分账' : '修改Adapay个人分账' " @ok="handleOkFunc" :confirmLoading="confirmLoading">

    <a-form-model ref="infoFormModel" :model="saveObject" :label-col="{span: 6}" :wrapper-col="{span: 15}" :rules="rules">
      <a-form-item label="appid">
        <a-select v-model="saveObject.appId" placeholder="请选择应用APPID" style="width:300px" :disabled="true">
            <a-select-option v-for="(item) in mchAppList" :key="item.appId" >{{ item.appName }} [{{ item.appId }}]</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-model-item label="请选择账户类型：" prop="accType" >
            <a-radio-group v-model="saveObject.accType" style="width:500px" :disabled="true">
              <a-radio name="accType" :value="0">个人私户</a-radio>
              <a-radio name="accType" :value="1"> 默认公户</a-radio>
              <a-radio name="accType" :value="2"> 企业公户</a-radio>
            </a-radio-group>
       </a-form-model-item>
       <a-form-model-item label="默认分账比例：" prop="divisionProfit">
        <a-input v-model="saveObject.divisionProfit" style="width: 100px" /> %
      </a-form-model-item>
        <a-form-model-item label="邮箱：" prop="email">
        <a-input v-model="saveObject.email" :readonly="true" />
      </a-form-model-item>
      <a-form-model-item label="姓名：" prop="nickname" >
        <a-input v-model="saveObject.nickname" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="身份证号：" prop="certId" >
        <a-input v-model="saveObject.certId" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="身份证截止日期：" prop="certIdExpires">
        <a-input v-model="saveObject.certIdExpires" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="联系电话：" prop="telNo">
        <a-input v-model="saveObject.telNo" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="地址：" prop="location">
        <a-input v-model="saveObject.location" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="开户卡号：" prop="cardId">
        <a-input v-model="saveObject.cardId" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="开户名称：" prop="cardName">
        <a-input v-model="saveObject.cardName" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item label="银行预留电话：" prop="certTelNo">
        <a-input v-model="saveObject.certTelNo"  :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="saveObject.accType == '2'" label="经营范围" prop="businessScope">
        <a-input v-model="saveObject.businessScope" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="saveObject.accType == '2'" label="企业名称" prop="enterpriseName">
        <a-input v-model="saveObject.enterpriseName" :readonly="true"/>
      </a-form-model-item>
       <a-form-model-item v-if="saveObject.accType == '2'" label="组织机构代码" prop="socialCreditCode">
        <a-input v-model="saveObject.socialCreditCode" :readonly="true"/>
      </a-form-model-item>
       <a-form-model-item v-if="saveObject.accType == '2'" label="组织机构代码有效期" prop="socialCreditCodeExpires">
        <a-input v-model="saveObject.socialCreditCodeExpires" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="saveObject.accType == '2'" label="银行代码" prop="bankCode">
        <a-input v-model="saveObject.bankCode" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="saveObject.accType == '2'" label="省份编码" prop="provCode">
        <a-input v-model="saveObject.provCode" :readonly="true"/>
      </a-form-model-item>
      <a-form-model-item v-if="saveObject.accType == '2'" label="区县编码" prop="areaCode">
        <a-input v-model="saveObject.areaCode" :readonly="true"/>
      </a-form-model-item>
     <a-form-model-item v-if="saveObject.accType == '2'" label="上传附件" prop="attachFile">
            <a-input v-model="saveObject.attachFile" disabled="disabled" />
            <JeepayUpload
              :action="action"
              :fileUrl="saveObject.attachFile"
              @uploadSuccess="uploadSuccess($event, 'attachFile')"
            >
              <template slot="uploadSlot" slot-scope="{loading}">
                <a-button style="marginTop:5px;"> <a-icon :type="loading ? 'loading' : 'upload'" /> {{ loading ? '正在上传' : '点击上传' }} </a-button>
              </template>
            </JeepayUpload>
          </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import JeepayUpload from '@/components/JeepayUpload/JeepayUpload'
import { API_URL_MCH_APP, API_URL_DIVISION_ADAPAYMEMBER, upload, req } from '@/api/manage'
export default {
  components: {
     JeepayUpload
  },
  props: {
    callbackFunc: { type: Function }
  },

  data () {
    return {
      action: upload.attachFile,
      confirmLoading: false, // 显示确定按钮loading图标
      isAdd: true, // 新增 or 修改页面标识
      isShow: false, // 是否显示弹层/抽屉
      saveObject: { autoDivisionFlag: 0, accType: 0 }, // 数据对象
      id: null, // 更新对象ID
      rules: {
        receiverGroupName: [
          { required: true, message: '请输入组名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
   mounted () {
    // 获取传入的参数，如果参数存在，则为appId 重新赋值
    const appId = this.$route.params.appId
    if (appId) {
      this.reqData.appId = appId // appId赋值
    }

    const that = this // 提前保留this
    // 请求接口，获取所有的appid，只有此处进行pageSize=-1传参
    req.list(API_URL_MCH_APP, { pageSize: -1 }).then(res => {
      that.mchAppList = res.records
    })
  },
  methods: {
    show: function (id) { // 弹层打开事件
      this.isAdd = !id
      this.saveObject = { autoDivisionFlag: 0, accType: 0 } // 数据清空
      this.confirmLoading = false // 关闭loading

      if (this.$refs.infoFormModel !== undefined) {
        this.$refs.infoFormModel.resetFields()
      }

      const that = this
      if (!this.isAdd) { // 修改信息 延迟展示弹层
        that.id = id
        req.getById(API_URL_DIVISION_ADAPAYMEMBER, id).then(res => {
          that.saveObject = res
          that.isShow = true
        })
      } else {
        that.isShow = true // 立马展示弹层信息
      }
    },
    // 上传文件成功回调方法，参数value为文件地址，name是自定义参数
    uploadSuccess (value, name) {
      this.saveObject[name] = value
      this.$forceUpdate()
    },
    handleOkFunc: function () { // 点击【确认】按钮事件
      const that = this
      this.$refs.infoFormModel.validate(valid => {
        if (valid) { // 验证通过
          // 请求接口

          that.confirmLoading = true // 显示loading

          if (that.isAdd) {
            req.add(API_URL_DIVISION_ADAPAYMEMBER, that.saveObject).then(res => {
              that.$message.success('添加成功')
              that.isShow = false
              that.callbackFunc() // 刷新列表
            }).catch(res => { that.confirmLoading = false })
          } else {
            req.updateById(API_URL_DIVISION_ADAPAYMEMBER, that.id, that.saveObject).then(res => {
              that.$message.success('修改成功')
              that.isShow = false
              that.callbackFunc() // 刷新列表
            }).catch(res => { that.confirmLoading = false })
          }
        }
      })
    }
  }
}
</script>
